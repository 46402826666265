import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import LogoSvg from '~/assets/logos/logo-32x--insomnia-core.svg';
import type { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { twMerge } from 'tailwind-merge';
import { CircularProgress } from '../circular-progress';
import { Icon } from '../icon';

type IconType = 'google' | 'github' | 'envelope' | 'key';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  icon: IconType;
  prefix?: IconPrefix;
  loading?: boolean;
};

const hoverBgColors: Record<IconType, string> = {
  google: 'group-hover:text-[#EA4335]',
  github: 'group-hover:text-[#24292e]',
  envelope: 'group-hover:text-[#FBBC05]',
  key: 'group-hover:text-primary',
};

export function AuthOption({ icon, prefix, loading, ...buttonProps }: Props) {
  return (
    <button
      disabled={buttonProps.disabled}
      type={buttonProps.type}
      name={buttonProps.name}
      value={buttonProps.value}
      className={twMerge(
        'group flex h-11 w-full cursor-pointer items-center overflow-hidden rounded border border-gray-300',
        buttonProps.disabled ? 'opacity-50' : 'hover:bg-gray-200',
      )}
      onClick={buttonProps.onClick}
    >
      <div className="flex h-full w-11 items-center justify-center border-r border-gray-300 bg-white">
        <Icon icon={icon} prefix={prefix} className={twMerge('h-5 w-5 text-gray-400', hoverBgColors[icon])} />
      </div>
      <span className="pl-2 text-[14px]">{buttonProps.children}</span>
      {loading && (
        <div className="flex flex-grow justify-end pl-2 pr-2">
          <CircularProgress />
        </div>
      )}
    </button>
  );
}

export function Header({ children }: { children: ReactNode }) {
  return <p className="text-md m-0 pb-[8px] pt-[24px] text-center font-medium text-gray-800">{children}</p>;
}

export function Footer({ children }: { children: ReactNode }) {
  return <p className="m-0 text-center text-[14px] font-normal text-gray-500">{children}</p>;
}

export function Tail({ children }: { children: ReactNode }) {
  return <div className="absolute bottom-[-32px] left-0 flex w-full justify-center">{children}</div>;
}

export function AuthorizeBox({ children, width = 380 }: { children: ReactNode; width?: number }) {
  return (
    <div className="relative z-10 flex min-h-[300px] w-full rounded bg-white" style={{ width }}>
      <div className="absolute top-[-32px] flex w-full flex-col items-center justify-center">
        <img className="w-16" src={LogoSvg} alt="logo" />
      </div>
      <div className="flex w-full flex-col gap-[16px] p-4">{children}</div>
    </div>
  );
}
